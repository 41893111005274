<template>
	<div id="line_site">
		<jy-query ref="form" :model="form">
			<jy-query-item prop="vehicleNo" label="车牌号:">
				<el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item prop="vin" label="vin:">
				<el-input placeholder="请输入" v-model="form.vin"></el-input>
			</jy-query-item>
			<!-- <jy-query-item prop="cName" label="司机姓名:">
                      <el-input placeholder="请输入" v-model="form.cName"></el-input>
                  </jy-query-item>
                  <jy-query-item prop="wNum" label="员工工号:">
                      <el-input placeholder="请输入" v-model="form.wNum"></el-input>
                  </jy-query-item> -->
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleBasicCarDriverList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')" v-if="btnexist('scheduleBasicCarDriverReset')">重置</el-button>
				<!-- <el-button type="primary" v-on:click="addUdate()">新增</el-button> -->
				<!-- <el-row>
					<el-button type="primary" v-on:click="addUdate()" v-if="btnexist('scheduleBasicCarDriverInsert')">新增</el-button>
					<el-button type="primary" @click="batchdelete" v-if="btnexist('scheduleBasicCarDriverBatchDelete')">批量删除</el-button>
				</el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" v-on:click="addUdate()" v-if="btnexist('scheduleBasicCarDriverInsert')">新增</el-button>
				<el-button type="primary" @click="batchdelete" v-if="btnexist('scheduleBasicCarDriverBatchDelete')">批量删除</el-button>
			</template>
		</jy-query>
		<div class="jy_table">
			<jy-table
				max-height="595"
				ref="jyTable"
				tooltip-effect="dark"
				@select-all="selectallchange"
				@select="selectchange"
				:data="dataList"
			>
				<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
				<jy-table-column type="selection" width="40"></jy-table-column>
				<jy-table-column label="车牌号" min-width="120">
					<template slot-scope="scope">
						{{ scope.row.vehicleInfo.plateNum }}
					</template>
				</jy-table-column>
				<jy-table-column label="vin" min-width="120">
					<template slot-scope="scope">
						{{ scope.row.vehicleInfo.vin }}
					</template>
				</jy-table-column>
				<jy-table-column label="司机姓名" min-width="120">
					<template slot-scope="scope">
						{{ scope.row.driverInfo.cName }}
					</template>
				</jy-table-column>
				<jy-table-column label="员工工号" min-width="120">
					<template slot-scope="scope">
						{{ scope.row.driverInfo.wNum }}
					</template>
				</jy-table-column>
				<jy-table-column prop="gender" label="性别" min-width="120">
					<template slot-scope="scope">{{ scope.row.driverInfo.gender | genderType }}</template>
				</jy-table-column>
				<jy-table-column prop="serviceState" label="供职状态" min-width="120">
					<template slot-scope="scope">{{ scope.row.driverInfo.sState | serType }}</template>
				</jy-table-column>
				<jy-table-column prop="phone" label="联系电话" min-width="120">
					<template slot-scope="scope">{{ scope.row.driverInfo.phone }}</template>
				</jy-table-column>
				<jy-operate v-bind:list="operateList" width="200"></jy-operate>
			</jy-table>
			<jy-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pageIndex"
				:page-size="pageSize"
				:total="total"
			></jy-pagination>
		</div>
		<add-update-cardriver ref="addUpdateOrg" @updateList="getList"></add-update-cardriver>
	</div>
</template>
<script>
import addUpdateCardriver from "@/components/pages/admin/resourcesAllocation/carDriver/addUpdateCardriver";
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			form: {
				cName: "",
				wNum: "",
				vehicleNo: "",
				vin: ""
			},
			batchdeleteList: [],
			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],
			operateList: [],
			// 设置行内元素样式
			setRowStyle({ row }) {
				if (row.serviceState == 0) {
					//   return 'color: red'
					return { color: "red" };
				}
			},

			btnMenuId: "scheduleBasicCarDriver"
		};
	},
	filters: {
		genderType(val) {
			if (val == "0") {
				return "女";
			} else if (val == "1") {
				return "男";
			}
		},
		serType(val) {
			if (val == "0") {
				return "离职";
			} else if (val == "1") {
				return "在职";
			}
		}
	},
	watch: {},
	mixins: [btnMixins],
	components: {
		addUpdateCardriver
	},

	computed: {},

	activated() {
		this.getList();
	},

	created() {
		this.setOperateList();
	},

	methods: {
		setOperateList() {
			let l = [
				{
					name: "修改",
					icon: "el-icon-edit-outline",
					fun: this.addUdate,
					isShow: () => {
						return this.btnexist("scheduleBasicCarDriverUpdate");
					}
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete,
					isShow: () => {
						return this.btnexist("scheduleBasicCarDriverDelete");
					}
				}
				// {
				//     name: '查看',
				//     icon: 'el-icon-view',
				//     fun: this.handlecheck
				// }
			];
			this.operateList = l;
		},
		getList() {
			let option = {
				vehicleDriver: {
					...this.form
				},
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/base/vehicle/driver/queryPage";
			this.$http.post(url, option).then(({ detail }) => {
				detail.list.forEach(item => {
					if (!item.driverInfo) {
						item.driverInfo = {};
					}
					if (!item.sysOrg) {
						item.sysOrg = {};
					}
					if (!item.vehicleInfo) {
						item.vehicleInfo = {};
					}
				});
				this.dataList = detail.list;
				this.total = detail.total;
				this.batchdeleteList = [];
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 勾选数据
		selectchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		addUdate(option) {
			if (option) {
				this.$refs.addUpdateOrg.init(option, "update");
			} else {
				this.$refs.addUpdateOrg.init();
			}
		},
		handlecheck(option) {
			this.$refs.addUpdateOrg.init(option, "view");
		},
		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该车辆司机分配?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					this.del(row.vehicleInfo.vehicleId);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		// 批量删除
		batchdelete() {
			if (this.batchdeleteList.length == 0) {
				this.$message({
					type: "warning",
					message: "请先选取数据"
				});
			} else {
				this.$confirm("是否删除所选中的车辆司机分配?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						let list = [...new Set(this.batchdeleteList.map(item => item.vehicleInfo.vehicleId))];
						this.del(list.join(","));
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除"
						});
					});
			}
		},
		del(id) {
			let delTotal = 0;
			let option = {
				vehicleId: id
			};
			this.$http.post("/base/vehicle/driver/queryByVehicleId", option).then(({ detail }) => {
				delTotal = detail.length;
				setTimeout(this.isDel(delTotal, id), 2000);
			});
		},
		isDel(delTotal, id) {
			let lastTotal = (this.pageIndex - 1) * this.pageSize;
			this.$http.post("/base/vehicle/driver/delete", id, { isRequestParam: false }).then(data => {
				this.$message.success(data.msg);
				if (this.pageIndex > 1 && this.total - delTotal <= lastTotal) {
					this.pageIndex -= 1;
				}
				this.getList();
			});
		},
		onexport() {
			console.log("onexport");
		},
		onimport() {
			console.log("onimport");
		}
	}
};
</script>
<style lang="scss" scoped></style>
